
export const orderModelToPrefix = (orderModel: string): string => {
  switch (orderModel) {
    case "Models::InboundOrder":
      return "IN";
    case "Models::OutboundOrder":
      return "OUT";
    case "Models::PrepOrder":
      return "PREP";
    case "Models::SpecialOrder":
      return "SLO";
    case "Models::TransportOrder":
      return "TRANS";
    default:
      return "";
  }
}

export const orderUrlConnect3 = (id: number, model: string): string => {
  const connectUrl = import.meta.env.VITE_CONNECT_URL;
  switch (model) {
    case "Models::InboundOrder":
      return `${connectUrl}/inbound/${id}`;
    case "Models::OutboundOrder":
      return `${connectUrl}/outbound/${id}`;
    case "Models::PrepOrder":
      return `${connectUrl}/prep/${id}`;
    case "Models::SpecialOrder":
      return `${connectUrl}/special/${id}`;
    case "Models::TransportOrder":
      return `${connectUrl}/transport/${id}`;
    default:
      return "#"
  }
}