<script setup lang="ts">
import { onMounted, ref, watch, reactive } from "vue";
import c3api from "@/c3api";

const props = defineProps<{
  reloadKey: number;
}>();

const chartOptions = reactive({
  chart: {
    toolbar: {
      show: false,
    },
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    width: "50%",
    id: "daily-orders-chart",
  },
  stroke: {
    colors: ["#3B51EC"],
  },
  tooltip: {
    theme: "dark",
  },
  xaxis: {
    categories: [],
    labels: {
      show: false, // hides the x-axis labels
    },
    axisBorder: {
      show: false, // hides the x-axis border
    },
    axisTicks: {
      show: true, // hides the x-axis ticks
    },
  },
  yaxis: {
    labels: {
      show: true, // hides the y-axis labels
    },
    axisBorder: {
      show: false, // hides the y-axis border
    },
    axisTicks: {
      show: true, // hides the y-axis ticks
    },
    title: {
      text: undefined, // removes the y-axis title
    },
  },
  grid: {
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    yaxis: {
      lines: {
        show: false, // This will hide the horizontal grid lines
      },
    },
  },
});

const series = reactive([
  {
    name: "940",
    data: [],
    color: "#477ae0",
  },
]);

const chartKey = ref(0);
const loading = ref(false);
const formatDate = (date: string) => {
  let newDate = new Date().toISOString().split("T")[0];
  console.log(newDate);
};

const getEdiStats = () => {
  loading.value = true;
  let start_date = new Date(new Date().setDate(new Date().getDate() - 14));
  let end_date = new Date();

  // Format dates as ISO strings in local timezone
  const formatDate = (date) => {
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().split("T")[0];
  };
  c3api
    .get(`/stats/edi_requests_per_day`, {
      params: {
        // start date 2 weeks ago and today formatted as "YYYY-MM-DD"
        start_date,
        end_date,
      },
    })
    .then((response) => {
      const data = response.data;
      const dates = data["945"].map((item: { date: any }) => item.date);

      // Extract counts for each code
      const counts945 = data["945"].map((item: { count: any }) => item.count);
      const counts944 = data["944"].map((item: { count: any }) => item.count);
      const counts943 = data["943"].map((item: { count: any }) => item.count);
      const counts940 = data["940"].map((item: { count: any }) => item.count);

      // Update chart options and series
      chartOptions.xaxis.categories = dates;
      series[0].data = counts940;
      series[1] = {
        name: "945",
        data: counts945,
        color: "#bc60b8", // Different color for the second line
      };
      series[2] = {
        name: "944",
        data: counts944,
        color: "#FFA500", // Different color for the third line
      };
      series[3] = {
        name: "943",
        data: counts943,
        color: "#84ba3f", // Different color for the fourth line
      };

      // Force chart re-render
      chartKey.value++;
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(getEdiStats);

watch(() => props.reloadKey, getEdiStats);
</script>

<template>
  <div v-if="!loading">
    <apexchart
      height="300px"
      width="98%"
      :key="chartKey"
      type="line"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
  <div v-else>Loading chart...</div>
</template>
