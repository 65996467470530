<script setup>
import { onMounted } from "vue";
import useOutboundOrders from "../../hooks/useOutboundOrders";

const { fetchMissing945s, outboundOrders, postOutboundEDIRequest } = useOutboundOrders();

onMounted(() => {
  fetchMissing945s();
});
</script>

<template>
  <div>
    <v-card flat class="mx-4">
      <v-table v-if="outboundOrders">
        <thead>
          <tr>
            <th class="text-left">ID</th>
            <th class="text-left">Client</th>
            <th class="text-left">Ref</th>
            <th class="text-left">PO</th>
            <th class="text-left">Items shipped?</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in outboundOrders.data" :key="order.id">
            <td>
              <RouterLink class="text-link" :to="`/outbound-orders/${order.id}`">{{
                `OUT-${order.id}`
              }}</RouterLink>
            </td>
            <td>{{ order.client.name }}</td>
            <td>{{ order.ref_number }}</td>
            <td>{{ order.po_number }}</td>
            <td>{{ order.items_shipped ? "Yes" : "No" }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
  </div>
</template>
