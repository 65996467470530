import { ref, reactive } from "vue";
import c3api from "../c3api";
import { useRouter } from "vue-router/auto";
import { useToast } from "vue-toastification";
import { unwrapReactive } from "../utils/unwrapReactive";
import { useLoadingStore } from "../stores/loading";

export default function useEdiRequests() {
	const ediRequests = ref(null);
	const toast = useToast();
	const defaultLocalState = reactive({});
	const router = useRouter();
	const loadingStore = useLoadingStore();
	const workflowStates = [
		{
			title: "Pending",
			value: "pending",
		},
		{
			title: "Failed",
			value: "failed",
		},
		{
			title: "Completed",
			value: "completed",
		},
		{
			title: "Retrying",
			value: "retrying",
		},
	];

	const requestCodes = [940, 943, 944, 945];

	const fetchEdiRequests = async (localState = defaultLocalState) => {
		loadingStore.state(true);
		try {
			const params = unwrapReactive(localState);
			const response = await c3api.get("/edi_requests", { params });
			ediRequests.value = response.data;
		} catch (error) {
			toast.error(error.response?.data?.data.join(". "));
		}
		loadingStore.state(false);
	};

	const retryEdiRequest = async (id, localState) => {
		loadingStore.state(true);
		let response = null;
		try {
			response = await c3api.patch(`/edi_requests/${id}/retry`);
			toast.success(`Successfully retried EDI Request ${id}.`);
			fetchEdiRequests(localState);
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		}
		loadingStore.state(false);
		return response.data.data;
	};

	const retryAllEdiRequest = async (localState) => {
		loadingStore.state(true);
		let response = null;
		try {
			response = await c3api.post(`/edi/outbounds/retry_all`);
			toast.success(`Successfully retried all EDI Requests.`);
			fetchEdiRequests(localState);
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		}
		loadingStore.state(false);
		return response.data.data;
	};

	const markComplete = async (id, localState) => {
		loadingStore.state(true);
		let response = null;
		try {
			response = await c3api.patch(`/edi_requests/${id}/complete`);
			toast.success(`EDI Request ${id} marked complete.`);
			fetchEdiRequests(localState);
		} catch (error) {
			toast.error(error.response.data.data.join(". "));
		}
		loadingStore.state(false);
		return response.data.data;
	};

	function updatePage(newPage) {
		router.push({ query: { page: newPage } });
	}

	const stateClass = (state) => {
		if (state == "retrying") {
			return "text-warning text-uppercase font-weight-bold";
		} else if (state == "failed") {
			return "text-error text-uppercase font-weight-bold";
		} else if (state == "completed") {
			return "text-success";
		}
	};

	return {
		ediRequests,
		fetchEdiRequests,
		retryEdiRequest,
		markComplete,
		updatePage,
		stateClass,
		requestCodes,
		workflowStates,
		retryAllEdiRequest,
	};
}
